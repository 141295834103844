export const initialState = {
  currentPlayer: {},
  backgroundPlayer: {},
};

const player = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_PLAYER':
      return {
        ...state,
        currentPlayer: {
          track: action.track,
          id: action.id,
          tracks: action.tracks,
        },
      };
    case 'RESET_CURRENT_PLAYER':
      return {
        ...state,
        currentPlayer: {
        },
      };
    default:
      return state
  }
}

export default player