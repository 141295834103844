export function classNames(obj) {
  const keys = Object.keys(obj);
  const classes = [];
  for (let i = 0; i < keys.length; i++) {
    if (obj[keys[i]]) {
      classes.push(keys[i]);
    }
  }

  return classes.join(' ');
}