import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindInstance } from 'util/dev';
import './Tray.css';
import { resetCurrentPlayer } from 'actions/player';
import { bindActionCreators } from 'redux';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AudioPlayer from 'components/AudioPlayer/AudioPlayer';

export class Tray extends Component {
  constructor() {
    super();
    bindInstance(this);
    this.state = {
      isExpanded: true,
    };
  }

  handleCollapse() {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded,
    });
  }

  handleClose() {
    const { resetCurrentPlayer } = this.props;
    resetCurrentPlayer();
  }

  render() {
    const { isExpanded } = this.state;
    const { Amplitude } = window;

    const { track, tracks = [] } = this.props.player && this.props.player.currentPlayer;
    const trayCls = isExpanded ? 'tray expanded' : 'tray collapse';
    const audioCls = isExpanded ? 'show' : 'hide';
    const trayVideo = tracks[0].track.album.videos[0].url,
      trayImage = tracks[0].track.album.images[0].url;
    return (
      <div 
        className={trayCls} 
        onClick={this.handleClick}
        onTouchMove={this.handleTouchMove}
      >
        <div className="tray-header font-bold">
          <div className="options">
            <div className="arrow" onClick={this.handleCollapse}>
            { isExpanded ? <KeyboardArrowDownIcon fontSize="large"/> : <KeyboardArrowUpIcon fontSize="large"/>}
            </div>
            <div className="closeX" onClick={this.handleClose}><HighlightOffIcon fontSize="large"/></div>
          </div>
          {
            Boolean(track) && 
            (<div className="content">
              <video
                className="tray-video"
                poster={trayImage}
                nocontrols="true"
                playsInline={true}
                loop={true}
                muted={true}
                autoPlay={true}
              >
                <source src={trayVideo} type="video/mp4" />
                {'Your browser does not support HTML5 video.'}
              </video>
              {tracks[0].track.name && <div>{tracks[0].track.name}</div>}
              <AudioPlayer
                Amplitude={Amplitude}
                cls={audioCls}
                src={track}
                loop={true}
              />
            </div>)
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    player: state.player,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetCurrentPlayer: bindActionCreators(resetCurrentPlayer, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Tray);