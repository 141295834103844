import React, { Component } from "react";
import { classNames } from '../../util/classNames';
import './AudioPlayer.css';
import { bindInstance } from 'util/dev';
import { getFirebasePlaylist } from 'actions/spotify';

// import AirplayIcon from '@material-ui/icons/Airplay';

export class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    bindInstance(this);
    this.playPauseBtn = React.createRef();
    this.amplitude = props.Amplitude;
    this.state = {
      playPauseCls: 'custom-play-pause amplitude-playing',
    }
  }

  componentDidMount() {
    getFirebasePlaylist().then(res=> {
      this.amplitude.init({
        songs: Object.values(res.data),
        autoplay: true,
      });
  
      if (this.playPauseBtn && this.playPauseBtn.current) {
        this.playPauseBtn.current.addEventListener('click', this.handleClickPlayPause);
      }
    
      this.amplitude.play();
    });
  }

  componentWillUnmount() {
    this.amplitude.pause();
    this.amplitude = null;
  }

  handleClickPlayPause(e) {
    const state = this.amplitude.getConfig().player_state;
    if (['stopped', 'paused'].includes(state)) {
      this.setState({
        playPauseCls: 'custom-play-pause amplitude-playing',
      });
      this.playPauseBtn.current.classList = 'custom-play-pause amplitude-playing';
      this.amplitude.play();
    } else  {
      this.playPauseBtn.current.classList = 'custom-play-pause amplitude-paused';
      this.amplitude.pause();
    }
  }

  render() {
    const { cls } = this.props;

    const audioCls = classNames({
      'audio-player': true,
      'hidden': cls === 'hide',
    })

    return (
    <div className={audioCls}>
      <div id="white-player-center">
        <div className="song-meta-data">
          <span data-amplitude-song-info="name" className="song-name"></span>
          <span data-amplitude-song-info="artist" className="song-artist"></span>
        </div>
        <div className="time-progress">
          <div id="progress-container">
            <input type="range" className="amplitude-song-slider"/>
            <progress id="song-played-progress" className="amplitude-song-played-progress"></progress>
            <progress id="song-buffered-progress" className="amplitude-buffered-progress" value="0"></progress>
          </div>
          <div className="time-container">
            <span className="current-time">
              <span className="amplitude-current-minutes"></span>:<span className="amplitude-current-seconds"></span>
            </span>
            <span className="duration">
                <span className="amplitude-duration-minutes"></span>:<span className="amplitude-duration-seconds"></span>
              </span>
          </div>
        </div>
      </div>
      <div id="white-player-controls">
        <div className="amplitude-shuffle amplitude-shuffle-off" id="shuffle"></div>
        <div className="amplitude-prev" id="previous"></div>
        <div className={this.state.playPauseCls} ref={this.playPauseBtn}></div>
        <div className="amplitude-next" id="next"></div>
        {/* <div className="airplay"><AirplayIcon/></div> */}
        <div className="amplitude-repeat amplitude-repeat-on" id="repeat"></div>
      </div>
    </div>);
  }
}

export default AudioPlayer;