import React, { Component } from "react";
import './Card.css';
import { connect } from 'react-redux';
import { getTrack, getTrackFreeSound } from 'actions/spotify';
import { bindInstance } from 'util/dev';
import { setCurrentPlayer, resetCurrentPlayer } from 'actions/player';
import { bindActionCreators } from 'redux';
import { firstCap } from 'util/string';

export class Card extends Component {
  constructor() {
    super();
    bindInstance(this);
  }

  handleClick() {
    const { id, setCurrentPlayer, resetCurrentPlayer, type, videoUrl, imageUrl } = this.props;
    const trackParams = {
      track: null,
      tracks: [{ 
        track: {
          album: {
            images: [{
              url: imageUrl,
            }],
            videos: [{
              url: videoUrl,
            }],
          },
        },
      }],
      id,
      type,
    };

    resetCurrentPlayer();
    if (type === 'freeSound') {
      getTrackFreeSound(id)
      .then((data) => {
        trackParams.track = data.track;
        setCurrentPlayer(trackParams);
      });
    } else if (type === 'spotify') {
      getTrack(id)
      .then((data) => {
        // trackParams.track = data.track;
        trackParams.track = "https://firebasestorage.googleapis.com/v0/b/somnium-san-mateo.appspot.com/o/Meditation-101.mp3?alt=media&token=0f2a6b39-b31a-4e59-9c4c-4312b4750763";
        setCurrentPlayer(trackParams);
      });
    }
  }

  render() {
    const { imageUrl, title } = this.props;
    return (
    <div className="card" onClick={this.handleClick}>
      <img src={imageUrl} alt={title}/>
      <div className="title">
        <div>
          {firstCap(title.replace(/[^\w\s]|\.*aif|wav/gi, ' ').trim())}
        </div>
      </div>
    </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPlayer: bindActionCreators(setCurrentPlayer, dispatch),
    resetCurrentPlayer: bindActionCreators(resetCurrentPlayer, dispatch),
  }
};

export default connect(null, mapDispatchToProps)(Card);