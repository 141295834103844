export function setCurrentPlayer({ track, id, tracks }) {
  return (dispatch) => {
    dispatch({
      type: 'SET_CURRENT_PLAYER',
      track,
      id,
      tracks,
    });
  }
}


export function resetCurrentPlayer() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_CURRENT_PLAYER',
    });
  }
}