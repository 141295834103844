export const initialState = {
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: {
          id: action.uid,
          email: action.email,
          name: action.displayName,
          photoUrl: action.photoURL,
          lastSignInTime: action.lastSignInTime,
          creationTime: action.creationTime,
        },
      };
    case 'RESET_CURRENT_USER':
      return {
        ...state,
        currentUser: {},
      };
    default:
      return state
  }
}

export default profile