import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore from './store';
import './index.css';
import App from './App';
import Nav from 'components/Nav/Nav';
import Header from 'components/Header/Header';
import Login from './components/Login/Login';
import { Route, Router, Redirect, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history';
const browserHistory = createBrowserHistory();

const store = configureStore();

if (window.location.hostname === 'localhost') {
  window.reduxStore = store;
}

ReactDOM.render(
 <Provider store={store}>
  <Router history={browserHistory}>
    <Header />
    <Nav />
    <Switch>
      <Redirect exact from="/" to="/home"/>
      <Route path="/home" component={App} />
      <Route path="/login" component={Login} />
      <Route path="/meditation" component={App} />
      <Route path="/profile" component={App} />
    </Switch>
  </Router>
 </Provider>,
 document.getElementById('root')
);