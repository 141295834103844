import { getPlaylist, getAudioFreeSoundPlayList, getVideosPexelsList } from 'actions/spotify';
export const DEFAULT_IMAGE = 'https://jw-webmagazine.com/wp-content/uploads/2019/06/jw-5d15da96c49484.64193252.jpeg';

export function setCards({ audioSearch, imageSearch = audioSearch, type }) {
  return (dispatch) => {
    if (type === 'spotify') {
      getVideosPexelsList(imageSearch)
      .then(mediaSet => {
        getPlaylist(audioSearch)
        .then(data => {
          const items = data.playlists.items || [];
          dispatch({
            type: 'SET_CARDS',
            collections: groupAudioPlayList(items, mediaSet && mediaSet.data, 'spotify'),
            search: audioSearch
          });
        });
      });
    } else if (type === 'freeSound') {
      getVideosPexelsList(imageSearch)
      .then(mediaSet => {
        getAudioFreeSoundPlayList(audioSearch)
        .then(res => {
          const items = res.data.results || [];
          dispatch({
            type: 'SET_CARDS',
            collections: groupAudioPlayList(items, mediaSet && mediaSet.data, 'freeSound'),
            search: audioSearch,
          });
        })
        .catch(e => {
          console.log(`error: cannot get Free Sound audios - ${audioSearch}`);
          return null;
        });
      })
      .catch(e => {
        console.log(`error: cannot get pexels videos - ${imageSearch}`);
        return null;
      });
    }
  }
}

export function groupAudioPlayList(items , mediaSet, type) {
  return items.map((item, i) => {
    if (i > 9) {
      return null;
    }

    return {
      imageUrl: (mediaSet && mediaSet.videos[i].video_pictures[0].picture) || DEFAULT_IMAGE,
      videoUrl: mediaSet && mediaSet.videos[i].video_files[0].link,
      title: item.name,
      description: item.tag && item.tag[0],
      externalUrl : null,
      trackHref: null,
      id: item.id,
      type,
    };
  }).filter(Boolean);
}