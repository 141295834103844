import React, { Component } from "react";
import './Login.css';
import { bindInstance } from 'util/dev';
import { firebaseUiInit } from 'actions/auth';
import { withRouter } from 'react-router';

export class Login extends Component {
  constructor(props) {
    super(props);
    bindInstance(this);
  }

  componentDidMount() {
    const { firebase, ui } = window;
    const { history } = this.props;

    firebaseUiInit({ firebase, ui });
    // var gProvider = new firebase.auth.GoogleAuthProvider();
    // var fbProvider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        history.replace('/home');
      } else {
        console.dir('No user is signed in');
      }
    });
  }

  render() {
    return (
      <div className="login">
        <div className="layer"/>
        <div className="hero">
          <div className="heading">{'Somnium'}</div>
          <div className="subheading">{'San Mateo, California'}</div>
        </div>
        <div id="firebaseui-auth-container"></div>
        <div className="video-wrapper">
          <video
            autoPlay={true}
            loop={true}
            className="loginVideo"
            type="video/mp4"
            playsInline={true}
            muted={true}
            src="https://firebasestorage.googleapis.com/v0/b/somnium-san-mateo.appspot.com/o/videos%2FSomnium%20Promo.mp4?alt=media&token=b41dfc83-e65b-421b-8fef-61b34918e2b5"
            poster=""
          >
            Your browser does not support HTML5 video.
          </video>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);