import { combineReducers } from 'redux';
import cards from 'reducers/cards';
import player from 'reducers/player';
import user from 'reducers/user';
import env from 'reducers/env';

export default combineReducers({
 cards,
 player,
 user,
 env,
});