export const initialState = {
  ...window.location,
};

const env = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default env