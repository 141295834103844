export function setCurrentUser(user) {
  return (dispatch) => {
    dispatch({
      type: 'SET_CURRENT_USER',
      ...user,
    });
  }
}


export function resetCurrentUser() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_CURRENT_USER',
    });
  }
}