import React, { Component } from "react";
import './CardCarousel.css';
import Card from 'components/Card/Card';
import '../../global/global.css';

export class CardCarousel extends Component {
  render() {
    const { cards } = this.props;
    return (
    <div className="card-carousel scrollbar">
      {
        cards.map((card, i) => {
          return <Card key={card.title + i} {...card } />
        })
      }
    </div>
    );
  }
}

export default CardCarousel;