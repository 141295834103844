import React, { Component } from "react";
import './App.css';
import { connect } from 'react-redux';
import Home from 'components/Home/Home';
import { isHomePage, isLoginPage } from './util/location';
import Tray from 'components/Tray/Tray';
import { setCurrentUser } from './actions/user';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Meditation from "components/Meditation/Meditation";
import Profile from 'components/Profile/Profile';

export class App extends Component {
  constructor(props) {
    super(props);
    this.backgroundMusic = React.createRef();
    this.audio = null;
    this.state = {
      showTray: false,
    }
  }

  componentDidMount () {
    const { firebase } = window;
    const { history, setCurrentUser, env } = this.props;

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log(user.displayName);
        setCurrentUser(user);
      } else {
        if (env.hostname !== 'localhost') {
          history.replace('/login');
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { currentPlayer } = this.props.player, prevPlayer = prevProps.player.currentPlayer;
    if (prevPlayer.id !== currentPlayer.id) {
      this.setState({
        showTray: true,
      });
    }
    
    if (prevPlayer.id && !currentPlayer.id) {
      this.setState({
        showTray: false,
      });
    }
  }

  render() {
    const { location } = this.props;
    const { showTray } = this.state;

    const isHome = isHomePage(location);
    const  isLogin = isLoginPage(location);
    const customClass = isLogin ? 'app app-login' : 'app'
    return (
      <React.Fragment>
        {
          location.pathname === '/meditation' &&
            <Meditation/>
        }
        {
          location.pathname === '/profile' && 
            <Profile/>
        }
        {
         ['/', '/home'].includes(location.pathname) &&
            <div className={customClass} scroll="no">
              {isHome && <Home location={location} />}  
            </div>
        }
        { showTray && <Tray/> }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    env: state.env,
    player: state.player,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: bindActionCreators(setCurrentUser, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));