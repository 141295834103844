import React, { Component } from "react";
import { withRouter } from 'react-router';
import './Header.css';

export class Header extends Component {
  render() {
    return (
      <div className="header">
        <span>{'Somnium'}</span>
        {/* <img src={'https://static-videos.pexels.com/videos/2248564/pictures/preview-0.jpg'} alth={''}/> */}
      </div>
    );
  }
}

export default withRouter(Header);