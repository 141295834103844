import Axios from 'axios';
import querystring from 'querystring';
export const API_HOST = 'https://us-central1-somnium-2020.cloudfunctions.net';
export const SEARCH_API = 'https://api.spotify.com/v1/search';
export const authToken = 'YWY1YTA2YzVlYWEwNDcyMWEyYjIzMTEwNzYxNmJkZjg6ZjRhZGZkMGFhNDUwNDkxYWJlOGZhNDJmMjM5Y2EzNTM';
const POST = 'POST', GET = 'GET';
export const TRACK_API = 'https://api.spotify.com/v1/playlists';
export const PEXELS_KEY_1 = '563492ad6f91700001000001f9e0258108c44e4e89a00838da6846df';
export const PEXELS_KEY_2 = '563492ad6f917000010000010b0aa73782cf45e68ae59097fb646e82';

export function getPlaylist(search) {
    return authenticateSpotify().then(token => {
      return Axios({
      method: GET,
      url: `${SEARCH_API}?q=${search}&type=playlist&limit=15`,
      headers: {
        'Authorization':`${token.data.token_type} ${token.data.access_token}`,
      },
    })
      .then(playlists => {
        return playlists.data;
      })
      .catch(e => {
        console.log(`error: cannot getPlaylist from Spotify`);
        return null;
      });
    });
}

export function getTrack(id) {
  return authenticateSpotify().then(token => {
    return Axios({
    method: GET,
    url: `${TRACK_API}/${id}/tracks`,
    headers: {
      'Authorization':`${token.data.token_type} ${token.data.access_token}`,
    },
  })
    .then(track => {
      return {
        track: track.data.items[0].track.preview_url,
        tracks: track.data.items,
      }
    })
    .catch(e => {
      console.log(`error: cannot getTrack from Spotify`);
      return null;
    });
  });
}


export function authenticateSpotify() {
  return Axios({
    method: POST,
    url: 'https://accounts.spotify.com/api/token',
    headers: {
      'Authorization': 'Basic ' + authToken,
    },
    data: querystring.stringify({ 
      grant_type: 'client_credentials',
    }),
  })
    .then(token => {
      return token;
    })
    .catch(e => {
      console.log(`error: cannot authenticateSpotify`);
      return null;
    });
}

export function getFirebasePlaylist() {
  return Axios({
    method: GET,
    url: 'https://us-central1-somnium-san-mateo.cloudfunctions.net/getPlaylistFromfirebase',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(e => {
      console.log(`error: cannot get getFirebasePlaylist`);
      return null;
    });
}

export function getToken() {
  return Axios
    .get(`${API_HOST}/getAuthKey`)
    .then(res => {
      return res.data;
    })
    .catch(e => {
      console.log(`error: cannot get getAuthKey`);
      return null;
    });
}


export function getTrackFreeSound(id) {
  return Axios({
    method: GET,
    url: `https://freesound.org/apiv2/sounds/${id}/?token=T0hgm9OOxXCxrGOmysVTNxzhZQVFtSyqUG8ycjVo`,
  })
  .then(track => {
    return {
      track: track.data.previews['preview-lq-mp3'] || track.data.previews['preview-lg-ogg'],
      tracks: [],
    }
  })
  .catch(e => {
    console.log(`error: cannot getTrackFreeSound`);
    return null;
  });
};

export function getAudioFreeSoundPlayList(search) {
  return Axios({
    method: GET,
    url: `https://freesound.org/apiv2/search/text/?&query=${search}&filter=duration%3A%5B60+TO+120%5D&sort=duration+desc&page_size=10&token=T0hgm9OOxXCxrGOmysVTNxzhZQVFtSyqUG8ycjVo`,
  })
    .then(data => {
      return data;
    })
    .catch(e => {
      console.log(`error: cannot getAudioFreeSoundPlayList`);
      return null;
    });
}


export function getVideosPexelsList(keyword, per_page = 20, page = 1) {
  return Axios({
    method: GET,
    url: `https://api.pexels.com/videos/search?query=${keyword}&per_page=${per_page}&page=${page}`,
    headers: {
      'Authorization': `Bearer ${PEXELS_KEY_1}`,
    },
  })
    .then(token => {
      return token;
    })
    .catch(e => {
      console.log(`error: cannot getVideosPexelsList`);
      return null;
    });
}


