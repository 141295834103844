import React, { Component } from "react";
import './Home.css';
import CardCarousel from 'components/CardCarousel/CardCarousel';
// import Splash from 'components/Splash/Splash';
import { setCards } from 'actions/cards';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { firstCap } from 'util/string';
import { bindInstance } from 'util/dev';

export class Home extends Component {
  constructor(props) {
    super(props);
    bindInstance(this);
    this.loaded = false;
    this.state = {
      cardCarousels: [],
      showTray: false,
      // showSplash: !localStorage.getItem('splash'),
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const prevPlaylist = prevProps.cards.playlist,
      currPlaylist = this.props.cards.playlist;
    if (Object.keys(prevPlaylist).length !== Object.keys(currPlaylist).length || !this.loaded) {
      this.setState({
        cardCarousels: this.renderCard(currPlaylist),
      })
      this.loaded = true;
    }
  }

  componentDidMount() {
    const currPlaylist = this.props.cards.playlist;
    // avoid fetching data again
    if (!Boolean(Object.keys(currPlaylist).length)) {
      this.fetchData();
    } else {
      this.setState({
        cardCarousels: this.renderCard(currPlaylist),
      });
    }
  }

  componentWillUnmount() {
    this.loaded = false;
  }

  fetchData() {
    // this.props.setCards({audioSearch: 'japan', imageSearch: 'Fuji Mountain', type: 'freeSound'});
    this.props.setCards({audioSearch: 'yoga', imageSearch: 'drone', type: 'spotify'});
    this.props.setCards({audioSearch: 'meditation', imageSearch: 'meditation', type: 'spotify'});
    this.props.setCards({audioSearch: 'jazz', imageSearch: 'music', type: 'spotify'});
  }
  
  renderCard(cards) {
    let dom = [];
    for (let key in cards) {
      dom.push(
      <div key={key} className="category">
        <span className="category-title">{firstCap(key)} </span>
        <CardCarousel cards={cards[key]}/>
      </div>);
    }

    return dom;
  }

  closeSplash () {
    this.setState({
      showSplash: false,
    })
  }

  render() {
    return (
      <React.Fragment>
       {/* { this.state.showSplash && <Splash closeSplash={this.closeSplash}/> } */}
        <div className="home">
          { this.state.cardCarousels }
        </div>
      </React.Fragment>);
    }
  }

const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    player: state.player,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCards: bindActionCreators(setCards, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);