const signInSuccessUrl =  'https://somnium-san-mateo.web.app/home';
const tosUrl = 'https://somnium-san-mateo.web.app/terms';

export function firebaseUiInit({ firebase, ui }) {
  // FirebaseUI config.
  var uiConfig = {
    signInSuccessUrl,
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        scopes: [
          'https://www.googleapis.com/auth/contacts.readonly'
        ],
        customParameters: {
          // Forces account selection even when one account
          // is available.
          prompt: 'select_account'
        }
      },
      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        scopes: [
          'public_profile',
          'email',
        ],
      },
      // {
      //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      //   signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
      // }
    ],
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl,
    // Privacy policy url/callback.
    privacyPolicyUrl: function() {
      window.location.assign(tosUrl);
    }
  };
  // The start method will wait until the DOM is loaded.
  ui.start('#firebaseui-auth-container', uiConfig);
}

export function firebaseSignOut(firebase, history) {
  const fAuth = firebase.auth();
  fAuth.signOut().then(function() {
    // Sign-out successful.
    history.replace('/login');
    console.log(`${fAuth.currentUser.displayName} has signed out`);
    // Sign-out successful.
  }).catch(function(error) {
    // An error happened.
  });
}