import { createRef } from 'react';
import { debounce, throttle } from 'throttle-debounce';

// bind methods and initial state to a Component instance, typically during its constructor
export function bindInstance(instance, options = {}) {
  // bind all methods
  Object.getOwnPropertyNames(Object.getPrototypeOf(Object(instance))).forEach(key => {
    if (typeof instance[key] === 'function') {
      try {
        const bound = instance[key].bind(instance);
        if (options.throttle && typeof options.throttle[key] === 'number') {
          instance[key] = throttle(options.throttle[key], bound);
        } else if (options.debounce && typeof options.debounce[key] === 'number') {
          instance[key] = debounce(options.debounce[key], bound);
        } else {
          instance[key] = bound;
        }
      } catch(e) {
        // instance is probably not a Component, and may have native unwritable methods, e.g. String's charAt
      }
    }
  });

  // set state
  if (options.initialState) {
    instance.state = options.initialState;
  }

  // create refs (can't use "this.refs" for legacy React reasons)
  if (options.ref && Array.isArray(options.ref)) {
    instance.ref = {};
    options.ref.forEach(ref => {
      instance.ref[ref] = createRef();
    });
  }
}

