import React, { Component } from "react";
import './Profile.css';
import { bindInstance } from 'util/dev';
import { firebaseSignOut } from 'actions/auth';
import { connect } from 'react-redux';

export class Profile extends Component {
  constructor(props) {
    super(props);
    bindInstance(this);
  }

  handleSignOut() {
    firebaseSignOut(window.firebase, this.props.history);
  }

  render() {
    const { email, id, photoUrl, name, lastSignInTime, creationTime } = this.props.currentUser;

    return (
      <div className="profile">
        <div className="profile-bg"/>
        <div className="profile-header">
          <img className="profile-img" src={photoUrl} alt={'profile img'}/>
          <div className="name">{name}</div>
          <div className="lastSignIn">{lastSignInTime}</div>
          <div className="creationTime">{creationTime}</div>
        </div>
        <div className="profile-header">
          <div>{'Account Info'}</div>
          <span>{email}</span>
        </div>
        {/* <div className="profile-subscription">
          <div>{'Subscription'}</div>
          <span>{'Monthly Plan ($4.99 Month)'}</span>
          <span>{'Next renewal Date: a certain date'}</span>
          <span>{'Active'}</span>
          <span>{'Your subscription will automatically renew unless you cancel 24 hours before the renewal date.'}</span>
        </div> */}

        <button className="btn-primary" onClick={this.handleSignOut}>{'Sign out'}</button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
}

export default connect(mapStateToProps, null)(Profile);