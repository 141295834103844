export function firstCap(str) {
  if (typeof str !== 'string') {
    return str;
  }
  const alwaysLowerCase = [
    'with',
    'without',
    'a',
    'an',
    'and',
    'as',
    'at',
    'but',
    'by',
    'en',
    'for',
    'if',
    'in',
    'of',
    'on',
    'or',
    'the',
    'to',
    's',
  ];
  const alwaysUpperCase = ['BBQ', 'BLTA', 'BLT', 'M&M'];
  const alwaysThisCase = ['Yummly.com', 'Yummly.co.uk', 'M&Ms', 'M&M\'s'];
  const replaceWord = word => {
    str = str.replace(new RegExp('\\b' + word + '\\b', 'gi'), word);
  };

  // can't use \b to identify word boundaries because it's not unicode-friendly, e.g. jalapeño will split at the ñ
  str = str.replace(/[-"_\\/|\s^](\w)/g, toUpper);

  // the real work:
  [alwaysLowerCase, alwaysUpperCase, alwaysThisCase].forEach(fn => {
    fn.forEach(replaceWord);
  });

  // if the first word was part of `alwaysLowerCase`, fix it here
  str = str.replace(/^(\w)/, toUpper);

  return str;
}

export function toUpper(str) {
  return typeof str === 'string' ? str.toUpperCase() : str;
}