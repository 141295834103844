import React, { Component } from "react";
import MusicNoteRoundedIcon from '@material-ui/icons/MusicNoteRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import AllInclusiveRoundedIcon from '@material-ui/icons/AllInclusiveRounded';
import { bindInstance } from 'util/dev';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import './Nav.css';

export class Nav extends Component {
  constructor(props) {
    super(props); 
    bindInstance(this);
    this.state = {
      currentTab: props.location.pathname,
    }
  }

  componentDidUpdate(prevProps) {
    const prevLocation = prevProps.location.pathname, 
      currLocation =  this.props.location.pathname;
    if (prevLocation !== currLocation) {
      this.setState({
        currentTab: currLocation,
      });
    }
  }

  render() {
    const currentTab = this.state.currentTab;
    return (
      <div className="nav">
        <Link className={`nav-link cursor-pointer ${['/', '/home'].includes(currentTab) ? 'active' : ''}`} to="/home">
          <HomeRoundedIcon/>
          {'Home'}
        </Link>
        <Link className={`nav-link cursor-pointer  ${currentTab === '/meditation' ? 'active' : ''}`} to="/meditation">
          <AllInclusiveRoundedIcon />
          {'Meditate'}
        </Link>
        <span className="nav-link cursor-pointer">
          <MusicNoteRoundedIcon />
          {'Music'}
        </span>
        <Link className={`nav-link cursor-pointer ${currentTab === '/profile' ? 'active' : ''}`} to="/profile">
          <PersonRoundedIcon />
          {'Profile'}
        </Link>
        {/* <span className="icon-logo">{'Somnium'}</span> */}
      </div>
    );
  }
}

export default withRouter(Nav);