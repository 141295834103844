import React, { Component } from "react";
import './Meditation.css';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

export class Meditation extends Component {
  render() {
    return (
      <div className="meditation">
        {/* <Link to="/7daysofCalm">{'7 days of Calm'}</Link> */}
        <div className="program">
          <div className="program-title h1-text">{'7 Days of Calm'}</div>
          <div className="program-subtitle">{'Learn the basics of mindfulness meditation'}</div>
          <div className="author">
            <div className="author-info">
              <img className="author-img" src="https://assets.calm.com/c828a114b963648abbafd03f103752e6.png" alt={'author img'}/>
              <div className="author-title">{'Tamara Levitt'}</div>
              <div className="author-subtitle">{'Head of Mindfullness at Calm'}</div>
            </div>
            <div className="program-content">
              <div className="program-content-row">
                <span className="program-content-num">{'1'}</span>
                <span className="program-content-title">{'Basics of Mindfulness'}</span>
                <span><FavoriteBorderIcon/></span>
              </div>
              <div className="program-content-row">
                <span className="program-content-num">{'2'}</span>
                <span className="program-content-title">{'Returning to the Here and Now'}</span>
                <span><FavoriteBorderIcon/></span>
              </div>
              <div className="program-content-row">
                <span className="program-content-num">{'3'}</span>
                <span className="program-content-title">{'Paying Attention'}</span>
                <span><FavoriteBorderIcon/></span>
              </div>
              <div className="program-content-row">
                <span className="program-content-num">{'4'}</span>
                <span className="program-content-title">{'Pulling Out of Autopilot'}</span>
                <span><FavoriteBorderIcon/></span>
              </div>
              <div className="program-content-row">
                <span className="program-content-num">{'5'}</span>
                <span className="program-content-title">{'The Value of Non-Doing'}</span>
                <span><FavoriteBorderIcon/></span>
              </div>
              <div className="program-content-row">
                <span className="program-content-num">{'6'}</span>
                <span className="program-content-title">{'Patience'}</span>
                <span><FavoriteBorderIcon/></span>
              </div>
              <div className="program-content-row">
                <span className="program-content-num">{'7'}</span>
                <span className="program-content-title">{'Awareness'}</span>
                <span><FavoriteBorderIcon/></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Meditation;