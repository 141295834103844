export const initialState = {
  playlist: {},
};

const cards = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CARDS':
      return {
        ...state,
        playlist: {
          ...state.playlist,
          [action.search]: action.collections,
        },
      };
    default:
      return state
  }
}

export default cards